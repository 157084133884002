import React from "react"
import dynamic from "next/dynamic"

const ActiveLink = dynamic(() => import("../buttons/active-link"), {
  ssr: true,
})
const LoginButton = dynamic(() => import("../buttons/login-button"), {
  ssr: true,
})
const Accordion = dynamic(() => import("../accordions/accordion"), {
  ssr: false,
})

function NavigationDrawer({
  items,
  classList,
  isHamburgerOpen,
  drawerRef,
  headerButtons,
  setIsHamburgerOpen,
  isDesktop
}) {
  return (
    <div
      className={`${classList} h-auto ${
        !isHamburgerOpen
          ? "pointer-events-none"
          : "overflow-auto max-h-70-screen h-auto"
      }`}
      ref={drawerRef}
    >
      <div
        className={`shadow-md relative z-10 bg-gray-400 transition-all duration-300 transform border-t-3px border-pink-500 ${
          isHamburgerOpen
            ? "opacity-100 visible scale-1"
            : "opacity-0 invisible scale-0"
        }`}
      >

        {headerButtons && !isDesktop && (
          <div className="bg-white">
            {headerButtons.items.map(button => (
              <React.StrictMode key={button.name}>
                {button.name !== "Order Online" ? (
                  <LoginButton 
                    isDesktop={isDesktop}
                    button={button} 
                    classList="text-right"
                    setIsHamburgerOpen={setIsHamburgerOpen}
                  />
                ) : null}
              </React.StrictMode>
            ))}
          </div>
        )}

        <ul className="mb-0 px-5 py-2">
          {items.map(item => (
            <React.Fragment key={item.name}>
              {item.itemsCollection.items.length > 0 ? (
                <Accordion
                  items={item.itemsCollection.items}
                  active={false}
                  item={item}
                  setIsHamburgerOpen={setIsHamburgerOpen}
                  data-layer-menu_item={item.name}
                />
              ) : (
                <li
                  className="mb-0 w-full border-b last:border-b-0 border-metal-900"
                  data-layer-menu_item={item.name}
                >
                  <ActiveLink
                    setIsHamburgerOpen={setIsHamburgerOpen}
                    href={`${item.slugUrl}`}
                    classList="transition-all duration-300 text-xxs text-black px-2 pb-2 pt-3 first:pl-0 last:pr-0 uppercase block hover:text-pink-500"
                  >
                    {item.name}
                  </ActiveLink>
                </li>
              )}
            </React.Fragment>
          ))}
        </ul>

        {headerButtons && isDesktop && (
          <div>
            {headerButtons.items.map(button => (
              <React.StrictMode key={button.name}>
                {button.name !== "Order Online" ? (
                  <LoginButton 
                    button={button} 
                    classList="text-right"
                    setIsHamburgerOpen={setIsHamburgerOpen}
                  />
                ) : null}
              </React.StrictMode>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default NavigationDrawer
